
const Synlait = {
    // Synlait Test Des : CaiqTest TRef
    'IgG (uncorrected value)' : 'IGG',
    'IgG (corrected value)' : 'IGG',
    'a-Lactalbumin' : 'WP_M_ALA',
    'a-Linolenic/TFA' : 'FA_CAL_3N3TFA',
    'Adenosine monophosphate (AMP)' : 'NUC_F_AMP',
    //APC_HONEY : 'Aerobic Plate Count',
    'Aerobic Plate Count' : ['APC','APC2','APC3','APC4','APC5'],
    'Aflatoxin M1' : 'AF_DP_M1',
    'Aluminium' : 'AL_ANL_AL',
    // SENS_S_CREAM : 'Appearance',
    // SENS_M_DWP : 'Appearance',
    // SENS_D_A : 'Appearance',
    // SENS_M_HONEY : 'Appearance',
    // SENS_S_UHT : 'Appearance',
    'Appearance' : ['SENS_M1_S1','SENS_M_S2','SENS_M','SENS_M1_S4'],
    'ARA (C20:4N6)/TFA' : 'FA_CAL_ARAT',
    'ARA (C20:4n-6)/TFA' : 'FA_CAL_ARAT',
    'ARA/TFA' : 'FA_CAL_ARAT',
    'ARA (C20:4N6)/DHA (C22:6N3)' : 'FA_CAL_ARADHA',
    'ARA/DHA' : 'FA_CAL_ARADHA',
    'Arsenic' : 'AS_ANL_AS',
    'Arsenic (As)' : 'AS_ANL_AS',
    'Ash' : 'ASH_F_ASH',
    //B_CEREUS_HONEY : 'Bacillus cereus Count, Bacillus cereus Detection',
    'Bacillus cereus Count' : ['B_CEREUS','B_CEREUS2','B_CEREUS3','B_CEREUS4','B_CEREUS5'],
    'Bacillus cereus Detection' : ['B_CEREUS','B_CEREUS2','B_CEREUS3','B_CEREUS4','B_CEREUS5'],
    'Benzo(a) pyrene' : 'BAP_F_BAP',
    'Benzo[a]pyrene' : 'BAP_F_BAP',
    'Benzylbutyl phthalate' : 'BBP',
    'B-Carotene' : 'BC_M_BC',
    // 'Biotin' : 'Biotin',
    // 'Biotin kJ' : 'Biotin',
    'Bulk Density' : 'BULK_DENSITY_AQ',
    'Bulk Density (0)' : 'BULK_DENSITY_AQ',
    'Bulk Density (100)' : 'BULK_DENSITY_AQ',
    'Bulk Density (1000)' : 'BULK_DENSITY_AQ',
    'Bulk Density (35)' : 'BULK_DENSITY_AQ',
    'Bulk Density (625)' : 'BULK_DENSITY_AQ',
    'Bulk Density (poured)' : 'BULK_DENSITY_AQ',
    'C10:0 (Capric)' : 'FA_M_C10-0',
    'C10:0 Capric' : 'FA_M_C10-0',
    'Capric (C10:0) as Triglyceride' : 'FA_M_C10-0',
    'Capric C10:0' : 'FA_M_C10-0',
    'C11:0 (Undecylic)' : 'FA_M_C11-0',
    'C11:0 Undecylic' : 'FA_M_C11-0',
    'C12:0 (Lauric)' : 'FA_M_C12-0',
    'C12:0 Lauric' : 'FA_M_C12-0',
    'Lauric (C12:0) as Triglyceride' : 'FA_M_C12-0',
    'Lauric acid' : 'FA_M_C12-0',
    'Lauric C12:0' : 'FA_M_C12-0',
    'C13:0 (Tridecylic)' : 'FA_M_C13-0',
    'C14:0 (Myristic)' : 'FA_M_C14-0',
    'C14:0 Myristic' : 'FA_M_C14-0',
    'Myristic acid' : 'FA_M_C14-0',
    'Myristic C14:0' : 'FA_M_C14-0',
    'C14:1 Myristoleic' : 'FA_M_C14-1N5',
    'C14:1 w5 (cis-Myristoleic)' : 'FA_M_C14-1N5',
    'C15:0 (Pentadecylic)' : 'FA_M_C15-0',
    'C15:0 Pentadecylic' : 'FA_M_C15-0',
    'C15:1 (10c) Pentadecenoic' : 'FA_M_C15-1N5',
    'C15:1 w5 (cis-Pentadecenoic)' : 'FA_M_C15-1N5',
    'C16:0 (Palmitic)' : 'FA_M_C16-0',
    'C16:0 Palmitic' : 'FA_M_C16-0',
    'Palmitic (C16:0) as Triglyceride' : 'FA_M_C16-0',
    'Palmitic C16:0' : 'FA_M_C16-0',
    //'missing' : 'FA_M_C16-1N7',
    'C17:0 (Margaric)' : 'FA_M_C17-0',
    'C17:0 Margaric' : 'FA_M_C17-0',
    'C17:1 Heptadecenoic' : 'FA_M_C17-1',
    'C18:0 (Stearic)' : 'FA_M_C18-0',
    'C18:0 Stearic' : 'FA_M_C18-0',
    'Stearic (C18:0) as Triglyceride' : 'FA_M_C18-0',
    'Stearic C18:0' : 'FA_M_C18-0',
    'C18:1 (9c) Oleic' : 'FA_M_C18-1N9C',
    'C18:1 (9t) Elaidic' : 'FA_M_C18-1N9T',
    'C18:2 (9c,12c) Linoleic (LA)' : 'FA_M_C18-2N6C',
    'C18:2 Linoleic g/100g' : 'FA_M_C18-2N6C',
    'C18:2 Linoleic total' : 'FA_M_C18-2N6C',
    'Linoleic (C18:2) as Triglyceride' : 'FA_M_C18-2N6C',
    'Linoleic Acid' : 'FA_M_C18-2N6C',
    'Linoleic acid' : 'FA_M_C18-2N6C',
    'Linoleic acid g' : 'FA_M_C18-2N6C',
    'Linoleic acid kJ' : 'FA_M_C18-2N6C',
    'Linoleic acid mg kJ' : 'FA_M_C18-2N6C',
    'Linoleic C18:2 n-6' : 'FA_M_C18-2N6C',
    'Linoleic g pb' : 'FA_M_C18-2N6C',
    'Linoleic pb' : 'FA_M_C18-2N6C',
    'Lauric+Myristic/TFA' : 'FA_CAL_LAUMYRTO',
    'Lauric + Myristic / TFA' : 'FA_CAL_LAUMYRTO',
    //'missing' : 'FA_M_C18-2N6T',
    //'missing' : 'FA_M_C18-2N6T2',
    //'missing' : 'FA_M_C18-2N6T3',
    'a-Linolenic (ALA) C18:3 n-3' : 'FA_M_C18-3N3',
    'a-Linolenic (ALA, C18:3 n-3) as Triglyceride' : 'FA_M_C18-3N3',
    'a-Linolenic acid' : 'FA_M_C18-3N3',
    'a-Linolenic acid g' : 'FA_M_C18-3N3',
    'a-Linolenic acid kJ' : 'FA_M_C18-3N3',
    'a-Linolenic ALA pb' : 'FA_M_C18-3N3',
    'Alpha-linolenic Acid' : 'FA_M_C18-3N3',
    'C18:3 (9c,12c,15c) a-Linolenic (ALA)' : 'FA_M_C18-3N3',
    'C18:3 a-Linolenic total' : 'FA_M_C18-3N3',
    //'missing' : 'FA_M_C18-3N6',
    'C20:0 (Eicosanoic/Arachidic)' : 'FA_M_C20-0',
    'C20:0 Arachidic' : 'FA_M_C20-0',
    'C20:1 w9 (cis-11-Eicosenoic)' : 'FA_M_C20-1',
    'C20:2 (11c,14c) Eicosadienoic' : 'FA_M_C20-2',
    'C20:2 w6 (cis-11,14-Eicosadienoic)' : 'FA_M_C20-2',
    'C20:3 (11c,14c,17c) Eicosatrienoic (ETE)' : 'FA_M_C20-3N3',
    'C20:3 (8c,11c,14c) Dihomo-g-linoleic (DGLA)' : 'FA_M_C20-3N6',
    'C20:3 w6 (cis-8,11,14-Eicosatrienoic)' : 'FA_M_C20-3N6',
    'ARA' : 'FA_M_C20-4N6',
    'ARA g' : 'FA_M_C20-4N6',
    'ARA kJ' : 'FA_M_C20-4N6',
    'Arachidonic ARA fb' : 'FA_M_C20-4N6',
    'C20:4 (5c,8c,11c,14c) Arachidonic (ARA)' : 'FA_M_C20-4N6',
    'C20:4 w6 (cis-Arachidonic)' : 'FA_M_C20-4N6',
    'C20:5 (5c,8c,11c,14c,17c) Eicosapentaenoic (EPA)' : 'FA_M_C20-5N3',
    'C20:5 w3 (cis-Eicosapentaenoic, EPA)' : 'FA_M_C20-5N3',
    'Eicosapentaenoic EPA pb' : 'FA_M_C20-5N3',
    'EPA' : 'FA_M_C20-5N3',
    'C21:0 (Heneicosanoic)' : 'FA_M_C21-0',
    'C21:0 Heneicosanoic' : 'FA_M_C21-0',
    'C22:0 (Behenic)' : 'FA_M_C22-0',
    'C22:0 Behenic' : 'FA_M_C22-0',
    'C22:1 (13c) Erucic' : 'FA_M_C22-1N9',
    'C22:1 w9 (cis-Erucic)' : 'FA_M_C22-1N9',
    'Erucic (C22:1) as Triglyceride' : 'FA_M_C22-1N9',
    'Erucic acid' : 'FA_M_C22-1N9',
    'Erucic C22:1 n-9' : 'FA_M_C22-1N9',
    'Erucic fb' : 'FA_M_C22-1N9',
    'Erucic pb' : 'FA_M_C22-1N9',
    'C22:2 (13c,16c) Docosadienoic' : 'FA_M_C22-2N6',
    'C22:2 w6 (cis-13,16-Docoadienoic)' : 'FA_M_C22-2N6',
    'C23:0 (Tricosanoic)' : 'FA_M_C23-0',
    'C24:0 (Lignoceric)' : 'FA_M_C24-0',
    'C24:0 Lignoceric' : 'FA_M_C24-0',
    'Lignoceric acid C24:0 (Fat basis)' : 'FA_M_C24-0',
    'C24:1 (15c) Nervonic' : 'FA_M_C24-1',
    'C24:1 w9 (cis-Nervonic)' : 'FA_M_C24-1',
    'C4:0 (Butyric)' : 'FA_M_C4-0',
    'C4:0 Butyric' : 'FA_M_C4-0',
    'C6:0 (Caproic)' : 'FA_M_C6-0',
    'C6:0 Caproic' : 'FA_M_C6-0',
    'C8:0 (Caprylic)' : 'FA_M_C8-0',
    'C8:0 Caprylic' : 'FA_M_C8-0',
    'Caprylic (C8:0) as Triglyceride' : 'FA_M_C8-0',
    'Caprylic C8:0' : 'FA_M_C8-0',
    'Cadmium' : 'CD_ANL_CD',
    'Calcium' : 'CA_ANL_CA',
    'Calcium g/100g' : 'CA_ANL_CA',
    'Calcium kJ' : 'CA_ANL_CA',
    'Calcium mg/kg' : 'CA_ANL_CA',
    'Ca/P' : ['CAP_CAL_CAP_S1','CAP_CAL_CAP_S2','CAP_CAL_CAP','CHO_F_CHO_CHILD'],
    'Carbohydrate' : ['CHO_F_CHO_S1','CHO_F_CHO_S2','CHO_F_CHO','CHO_F_CHO_CHILD'],
    'Carbohydrate (available)' : ['CHO_F_CHO_S1','CHO_F_CHO_S2','CHO_F_CHO','CHO_F_CHO_CHILD'],
    'Carbohydrate kJ' : ['CHO_F_CHO_S1','CHO_F_CHO_S2','CHO_F_CHO','CHO_F_CHO_CHILD'],
    'Total Carbohydrate' : ['CHO_F_CHO_S1','CHO_F_CHO_S2','CHO_F_CHO','CHO_F_CHO_CHILD'],
    'Chloride' : 'CL_F_CL_I',
    'Chloride %' : 'CL_F_CL_I',
    'Chloride kJ' : 'CL_F_CL_I',
    //'missing' : 'CL_F_CL_III',
    //'missing' : 'CH_M_CH_3',
    'Choline' : 'CH_M_CH',
    'Choline kJ' : 'CH_M_CH',
    'Chromium' : 'CR_ANL_CR',
    'Chromium mg/kg' : 'CR_ANL_CR',
    'Coliforms Count (MPN)' : 'COLI_MPN',
    'Coliforms Detection' : 'COLI_MPN',
    //'missing' : 'SENS_C_CREAM',
    //'missing' : 'SENS_C_DWP',
    //'missing' : 'SENS_D_C',
    //'missing' : 'SENS_C_HONEY',
    //'missing' : 'SENS_C_UHT',
    'Color' : ['SENS_C_S1','SENS_C_S2','SENS_C','SENS_C_S4'],
    'Colour' : ['SENS_C_S1','SENS_C_S2','SENS_C','SENS_C_S4'],
    'Commercial sterilisation' : 'CML_STER',
    'Copper' : 'CU_ANL_CU',
    'Copper kJ' : 'CU_ANL_CU',
    'Copper mg/kg' : 'CU_ANL_CU',
    //'missing' : 'C_SAKAZAKII_HON',
    'Cronobacter sakazakii' : ['C_SAKAZAKII','C_SAKAZAKII2','C_SAKAZAKII3','C_SAKAZAKII4','C_SAKAZAKII5'],
    'Enterobacter sakazakii' : ['C_SAKAZAKII','C_SAKAZAKII2','C_SAKAZAKII3','C_SAKAZAKII4','C_SAKAZAKII5'],
    //'missing' : ['C_SPP1','C_SPP2','C_SPP3','C_SPP4','C_SPP5'],
    'Cytidine monophosphate (CMP)' : 'NUC_F_CMP',
    'C22:6 (4c,7c,10c,13c,16c,19c) Docosahexaenoic (DHA)' : 'FA_M_C22-6N3',
    'C22:6 w3 (Docosahexaenoic, DHA)' : 'FA_M_C22-6N3',
    'DHA' : 'FA_M_C22-6N3',
    'DHA as triglyceride' : 'FA_M_C22-6N3',
    'DHA g' : 'FA_M_C22-6N3',
    'DHA kJ' : 'FA_M_C22-6N3',
    'Docosahexaenoic acid (DHA)' : 'FA_M_C22-6N3',
    'Docosahexaenoic DHA fb' : 'FA_M_C22-6N3',
    'Docosahexaenoic DHA pb' : 'FA_M_C22-6N3',
    //'missing' : 'FA_M_C22-6N3M2',
    'DHA (C22:6N3)/TFA' : 'FA_CAL_DHAT',
    'DHA (C22:6N3)/ARA (C20:4N6)' : 'FA_CAL_DHAARA',
    //'missing' : 'FA_CAL_DHAEPA',
    'Di(2-ethylhexyl) phthalate' : 'DEHP',
    'Di-m-butyl phthalate' : 'DBP',
    'Di-n-butyl phthalate' : 'DBP',
    'Fibre' : 'DIETARY_CAL_DF',
    'Fibre kJ' : 'DIETARY_CAL_DF',
    'Fibre mg' : 'DIETARY_CAL_DF',
    'Diethyl phthalate' : 'DINP',
    'Energy' : ['NRG_F_NRG_S1','NRG_F_NRG_S2','NRG_F_NRG','NRG_F_NRG_CHILD'],
    'Energy kJ' : ['NRG_F_NRG_S1','NRG_F_NRG_S2','NRG_F_NRG','NRG_F_NRG_CHILD'],
    'Energy kJ/100g' : ['NRG_F_NRG_S1','NRG_F_NRG_S2','NRG_F_NRG','NRG_F_NRG_CHILD'],
    //'missing' : ['EB_PF1','EB_PF2','EB_PF3','EB_PF4','EB_PF5'],
    'EPA/DHA' : 'FA_CAL_EPADHA',
    //'missing' : 'EQI_ANTIMIC',
    //'missing' : 'EQI_ANTIMIC_MP',
    //'missing' : 'EQI_ANTIMIC_UHT',
    'Erucic Acid/TFA' : 'FA_CAL_ERUTOTL',
    'Erucic/TFA' : 'FA_CAL_ERUTOTL',
    'Escherichia coli' : ['E_COLI','E_COLI2','E_COLI3','E_COLI4','E_COLI5'],
    //'missing' : 'E_COLI_DND',
    'Ethyl Vanillin' : 'ETHVANILLIN_AQ',
    'Fat' : 'FAT_MP_FAT',
    'Fat g/kg' : 'FAT_MP_FAT',
    'Fat g/100mL' : 'FAT_MP_FAT',
    'Fat kJ' : 'FAT_MP_FAT',
    //'missing' : 'FAT_PF_FAT',
    'Nitrate' : 'NOX_D_NO3',
    'Nitrite' : 'NOX_D_NO2',
    //'missing' : 'NOX_UHT_NO2',
    'Folic acid DFE' : 'F_ACID_DFE',
    'Folic Acid ug/DFE' : 'F_ACID_DFE',
    //'missing' : 'F_ACID',
    'Folic Acid' : 'F_ACID_NORMAL',
    'Folic acid' : 'F_ACID_NORMAL',
    'Folic acid kJ' : 'F_ACID_NORMAL',
    'Foreign Matter' : 'FOREIGNMATTER',
    'Foreign Matter ND/D' : 'FOREIGNMATTER',
    //'missing' : 'SENS_R_HONEY',
    'Fructose' : 'SUG_F_FRU',
    'Furosine' : 'FS_PUM_FS',
    'Glucose' : 'SUG_F_GLU',
    //'missing' : 'SUG_HON_GLUFRU',
    //'missing' : 'OPO_M_C51',
    //'missing' : 'OPO_M_PPP',
    //'missing' : 'OPO_M_PPP_IANZ',
    //'missing' : 'GOS_F_GOS_IANZ',
    'GOS' : 'GOS_F_GOS',
    'GOS kJ' : 'GOS_F_GOS',
    'Guanosine monophosphate (GMP)' : 'NUC_F_GMP',
    'Impurities' : 'IMP',
    'Impurity' : 'IMP',
    'Inhibitory Substances' : 'INHIB_SUB',
    'Inosine monophosphate (IMP)' : 'NUC_F_IMP',
    'Inositol' : 'IST_MP_IST',
    'Inositol kJ' : 'IST_MP_IST',
    //'missing' : 'EQI_CAL',
    //'missing' : 'EQI_CAL_MP',
    //'missing' : 'EQI_CAL_UHT',
    //'missing' : 'EQI_CAL_UHT',
    'Insolubility Index (24°C)' : 'INSOLUBILITY_IN',
    //'missing' : 'FOS_F_INULIN',
    'Iodine' : 'I_MP_I',
    'Iodine kJ' : 'I_MP_I',
    'Iodine mg' : 'I_MP_I',
    //'missing' : 'I_M_I',
    'Iron' : 'FE_ANL_FE',
    'Iron kJ' : 'FE_ANL_FE',
    'Iron mg/100g' : 'FE_ANL_FE',
    'Iron mg/kg' : 'FE_ANL_FE',
    'L-carnitine' : 'LCA_M_LCA',
    'L-carnitine kJ' : 'LCA_M_LCA',
    //'missing' : 'L_ACID_BAC',
    //'missing' : 'LF_LM_LF',
    //'missing' : 'LF_MP_LF',
    //'missing' : 'LF_DP_LF',
    'Lactoferrin' : 'LF_F_LF',
    'Lactoferrin kJ' : 'LF_F_LF',
    //'missing' : 'LPO_ACT',
    //'missing' : 'LPO_ACT_MP',
    //'missing' : 'LPO_ACT_UHT',
    //'missing' : 'SUG_F_LAC_54135',
    'Lactose' : 'SUG_F_LAC',
    'Lactose/ Total Carbohydrates' : ['SUG_LACCHO_S1','SUG_LACCHO_S2','SUG_LACCHO_S3','SUG_CAL_LACCHO'],
    'Lactulose' : 'LACTULOSE',
    'Lauric acid + Myristic acid' : 'FA_CAL_C12C14',
    //'missing' : 'PB_ANL_UHT',
    'Lead' : 'PB_ANL_PB',
    'Linoleic/TFA' : 'FA_CAL_2N6CTFA',
    'Linoleic acid/ a-Linolenic acid' : 'FA_M_2N6C_3N3_C',
    //'missing' : 'LISTERIA_HONEY',
    //'missing' : 'LISTERIA_M2',
    'Listeria' : ['LISTERIA','LISTERIA-2','LISTERIA-3','LISTERIA-4','LISTERIA-5'],
    'Lutein' : 'LUT_M_LUT',
    'Magnesium' : 'MG_ANL_MG',
    'Magnesium kJ' : 'MG_ANL_MG',
    'Magnesium mg/kg' : 'MG_ANL_MG',
    'Maltose' : 'SUG_F_MAL',
    'Manganese' : 'MN_ANL_MN',
    'Manganese kJ' : 'MN_ANL_MN',
    'Manganese mg/kg' : 'MN_ANL_MN',
    //'missing' : 'MEDI_PREP',
    //'missing' : 'MEL_M_MEL',
    'Melamine' : 'MEL_DP_MEL',
    'Mercury' : 'HG_ANL_HG',
    'Mercury mg/kg' : 'HG_ANL_HG',
    'Total Mercury' : 'HG_ANL_HG',
    'Moisture' : 'MOI_F_MOI',
    //'missing' : 'FA_M_MUFA',
    //'missing' : 'MOULDS_HONEY',
    'Moulds' : ['MOULDS','MOULDS2','MOULDS3','MOULDS4','MOULDS5'],
    //'missing' : 'MSNF',
    'Niacin' : 'VITPP_M_NIA',
    'Niacin (as niacinamide)' : 'VITPP_M_NIA',
    'Niacin kJ' : 'VITPP_M_NIA',
    'Niacin mg' : 'VITPP_M_NIA',
    'Niacinamide' : 'VITPP_M_NIA',
    'Vitamin B3 (Niacin)' : 'VITPP_M_NIA',
    'Nickel' : 'NI_ANL_NI',
    'Nicotinamide' : 'VITPP_M_NTM',
    //'missing' : 'VITPP_M_NCT',
    //'missing' : 'NPA',
    //'missing' : 'NF_CAL_SOLID',
    //'missing' : 'NF_LM_SOLID',
    //'missing' : 'SENS_C&T_CREAM',
    //'missing' : 'SENS_D_OT',
    //'missing' : 'SENS_O&T_DWP',
    //'missing' : 'SENS_C&T_HONEY',
    //'missing' : 'SENS_C&T_UHT',
    'Odor and Taste' : ['SENS_C&T1_S1','SENS_O&T_S2','SENS_C&T','SENS_C&T1_S4'],
    //'missing' : 'FOS_F_OLIG',
    'OMEGA 3' : 'FA_M_OMG3',
    'Omega 3 Total' : 'FA_M_OMG3',
    'OMEGA 6' : 'FA_M_OMG6',
    'Omega 6 Total' : 'FA_M_OMG6',
    //'missing' : 'FA_CAL_OMG3T',
    //'missing' : 'FA_CAL_OMG6T',
    //'missing' : 'FA_CAL_OMG6OMG3',
    'OPO' : 'OPO_M_OPO_IANZ',
    'OPO kJ' : 'OPO_M_OPO_IANZ',
    //'missing' : 'O_YEAST',
    'Pantothenic Acid' : 'VITB5_M_B5',
    'Pantothenic acid' : 'VITB5_M_B5',
    'Pantothenic acid kJ' : 'VITB5_M_B5',
    'Pantothenic acid mg' : 'VITB5_M_B5',
    'pH' : 'PH',
    'Phosphorus' : 'P_MP_P',
    'Phosphorus kJ' : 'P_MP_P',
    'Phosphorus mg/kg' : 'P_MP_P',
    'Polyfructose' : 'FOS_F_POLY',
    'Polyunsaturated Fatty Acids' : 'FA_M_PUFA',
    'Potassium' : 'K_ANL_K',
    'Potassium kJ' : 'K_ANL_K',
    'Potassium mg/kg' : 'K_ANL_K',
    //'missing' : 'PRO_MSNF',
    'Protein' : 'PRO_F_PRO',
    'Protein kJ' : 'PRO_F_PRO',
    'Protein g/kg' : 'PRO_F_PRO',
    'Protein 6.25' : 'PRO_F_PRO',
    'Protein ( Conversion factor 6.38)' : 'PRO_DP_PRO',
    'Protein (6.38)' : 'PRO_DP_PRO',
    'Protein (conversion factor 6.38)' : 'PRO_DP_PRO',
    'Protein KJ (conversion factor 6.38)' : 'PRO_DP_PRO',
    //'missing' : 'VITB6_M_DXL',
    //'missing' : 'VITB6_M_DXM',
    'Pyridoxine' : 'VITB6_M_DXNRAW',
    //'missing' : ['SENS_R1_S1','SENS_R_S2','SENS_R'],
    'Salmonella' : ['SAL_NUM','SAL_NUM2','SAL_NUM3','SAL_NUM4','SAL_NUM5'],
    'Salmonellas' : ['SAL_NUM','SAL_NUM2','SAL_NUM3','SAL_NUM4','SAL_NUM5'],
    //'missing' : ['SAL_NUM1_PF','SAL_NUM2_PF','SAL_NUM3_PF','SAL_NUM4_PF','SAL_NUM5_PF'],
    //'missing' : 'SALMONELLA',
    'Saturated fat' : 'FA_M_SF',
    'Saturated Fatty Acids' : 'FA_M_SFA',
    'Scorched Particles' : 'SCORCHED_PARTAQ',
    'Total Scorched Particles' : 'SCORCHED_PARTAQ',
    'Selenium' : 'SE_ANL_SE',
    'Selenium kJ' : 'SE_ANL_SE',
    'Selenium mg/kg' : 'SE_ANL_SE',
    'Sodium' : 'NA_ANL_NA',
    'Sodium kJ' : 'NA_ANL_NA',
    'Sodium mg/kg' : 'NA_ANL_NA',
    'Solubility' : 'SENS_S',
    //'missing' : 'S_AUREUS_QUA',
    'Staphylococcus aureus' : ['S_AUREUS_ENU','S_AUREUS_ENU2','S_AUREUS_ENU3','S_AUREUS_ENU4','S_AUREUS_ENU5'],
    'Sucrose' : 'SUG_F_SUC',
    'Total Sugars' : 'SUG_F_TOTAL',
    //'missing' : 'SUM_MEAS_PRO',
    //'missing' : 'SUM_MS_PRO_MP',
    //'missing' : 'SUM_MS_PRO_UHT',
    'Taurine' : 'TAU_M_TAU',
    'Taurine kJ' : 'TAU_M_TAU',
    'Tin' : 'SN_ANL_SN',
    //'missing' : 'ACID_CP_TA',
    //'missing' : 'ACID_CH_TA',
    //'missing' : 'ACID_CR_TA',
    //'missing' : 'ACID_UM_TA',
    'Titratable Acidity' : 'ACID_MP_TA',
    //'missing' : 'VITC_HPLC',
    'Coliform Count' : ['COLI_TCC','COLI_TCC2','COLI_TCC3','COLI_TCC4','COLI_TCC5'],
    'Coliforms Count' : ['COLI_TCC','COLI_TCC2','COLI_TCC3','COLI_TCC4','COLI_TCC5'],
    'Total coliform' : ['COLI_TCC','COLI_TCC2','COLI_TCC3','COLI_TCC4','COLI_TCC5'],
    'Total Coliforms' : ['COLI_TCC','COLI_TCC2','COLI_TCC3','COLI_TCC4','COLI_TCC5'],
    'Total Fatty Acid' : 'FA_M_TOL',
    'Total Fatty Acid pb' : 'FA_M_TOL',
    'Total fatty acids' : 'FA_M_TOL',
    'Total Nucleotide' : 'NUC_F_TOTAL',
    'Total Nucleotide kJ' : 'NUC_F_TOTAL',
    //'missing' : 'FA_M_TRANS',
    'Trans Fatty Acids' : 'TRANS_MP_TRANS',
    'Trans fatty acids' : 'TRANS_MP_TRANS',
    'Trans fatty acids/TFA' : 'FA_C_TRANSTOTAL',
    'Trans FA/TFA' : 'FA_C_TRANSTOTAL',
    'Urease' : 'UR_IFMP_UR',
    'Uridine monophosphate (UMP)' : 'NUC_F_UMP',
    'Vanillin' : 'VANILLIN_AQ',
    'Vitamin A' : 'VITADE_MP_A',
    'Vitamin A kJ' : 'VITADE_MP_A',
    //'missing' : 'VITADE_UHT_A',
    'Vitamin B1' : 'VITB1_M_B1',
    'Vitamin B1 kJ' : 'VITB1_M_B1',
    'Vitamin B1 mg' : 'VITB1_M_B1',
    'Vitamin B12' : 'VITB12_MP_B12',
    'Vitamin B12 kJ' : 'VITB12_MP_B12',
    'Vitamin B2' : 'VITB2_M_B2',
    'Vitamin B2 mg' : 'VITB2_M_B2',
    'Vitamin B2 kJ' : 'VITB2_M_B2',
    'Vitamin B6' : 'VITB6_M_DXN',
    'Vitamin B6 kJ' : 'VITB6_M_DXN',
    'Vitamin B6 mg' : 'VITB6_M_DXN',
    'Vitamin C' : 'VITC_F_C',
    'Vitamin C kJ' : 'VITC_F_C',
    //'missing' : 'VITADE_UHT_D3',
    'Vitamin D3' : 'VITADE_MP_D3',
    'Vitamin D3 kJ' : 'VITADE_MP_D3',
    'Vitamin E' : 'VITADE_MP_E',
    'Vitamin E kJ' : 'VITADE_MP_E',
    'Vitamin K1' : 'VITK1_F_K1',
    'Vitamin K1 kJ' : 'VITK1_F_K1',
    'Whey Protein' : 'WP_MP_TOL',
    'Whey Protein kJ' : 'WP_MP_TOL',
    //'missing' : 'WP_M_TOL',
    //'missing' : 'WP_M_TOL_G',
    //'missing' : 'WP_M_TOL_IANZ',
    'Whey Protein/ Protein' : ['WP_M_WP_S1','WP_M_WP_S2','WP_M_WP_S3','WP_M_WP'],
    //'missing' : ['WP_M_WP_S1_IANZ','WP_M_WP_S2_IANZ','WP_M_WP_S3_IANZ'],
    //'missing' : 'WP_BP_WP',
    //'missing' : 'YEASTS_MOULDS_H',
    //'missing' : 'YEASTS_HONEY',
    'Yeast and Mould' : ['YEASTS_MOULDS','YEASTS_MOULDS2','YEASTS_MOULDS3','YEASTS_MOULDS4','YEASTS_MOULDS5'],
    'Yeasts and Moulds' : ['YEASTS_MOULDS','YEASTS_MOULDS2','YEASTS_MOULDS3','YEASTS_MOULDS4','YEASTS_MOULDS5'],
    'Yeast' : ['YEASTS','YEASTS2','YEASTS3','YEASTS4','YEASTS5'],
    'Yeasts' : ['YEASTS','YEASTS2','YEASTS3','YEASTS4','YEASTS5'],
    'Zinc' : 'ZN_ANL_ZN',
    'Zinc kJ' : 'ZN_ANL_ZN',
    'Zinc mg/kg' : 'ZN_ANL_ZN',
    'Zinc:Copper' : 'ZNCU_CAL_ZNCU',

};


const readClientCsvFileContent = (csvContentArray,setResponseMsg,setResponseStatus,setCompanyName,setClientReference,setAddress,setPhone,
    setQuoteNo,setOrderNo,setContactPerson,setEmail,setSubmitter,setSubmitterEmail,setInvoiceTo,setAdditionalComments,setBatchNo,setProductionDate,
    setExpirationDate,setManufacturer,setApprovalNo,setOriginPlace,setImporter,setQuantity,setWeightUnit,setSampleColor,setUnitId,setInitialImport,
    setSampleForm,setPackingMode,setPrePackagedType,setStorageRequirement,setOtherStorageRequirement,setProductCategory,productCategoryTests,
    setSampleName,setTestInfo,setTestInfoErrMsg) => {

    let user = localStorage.getItem("access-user")
            ? JSON.parse(localStorage.getItem("access-user"))
            : null;

    let matchingObj = null;
    if(user && user.company) {
        switch(user.company) {
            case 'SYNLAIT':
                matchingObj = Synlait;

                //submission form fields
                let orderNumIndex = null;            //PO Number
                let contactPersonIndex = null;       //Contact Person
                let emailIndex = null;               //Email
                let submitterNotClIndex = null;      //Submitter
                let submitterEmailIndex = null;      //Submitter Eamil
                let sampleNameIndex = null;          //Sample Number, for test additonal note
                let batchNumIndex = null;            //Lot
                let productionDateIndex = null;      //Production Date
                let expirationDateIndex = null;      //Expiration Date
                let manufacturerIndex = null;        //Manufacturer
                let pooIndex = null;                 //Origin
                let sampleFormIndex = null;          //Sample Form
                let ModesOfPackingIndex = null;      //Mode of Packing
                let storageRequirementIndex = null;  //Storage Requirement
                let productCategoryIndex = null;     //Product Category
                //test items, test additonal note

                // CSV column
                let resultNameColIndex = null;        // for test items

                let requiredCols = ['PO Number','Contact Person','Email','Submitter','Submitter Email','Sample Number','Lot','Production Date','Expiration Date',
                        'Manufacturer','Origin','Sample Form','Mode of Packing','Storage Requirement','Product Category','Result Name'];
                csvContentArray[0].forEach((item, i) => {
                    let index = requiredCols.indexOf(item);
                    if (index > -1) {
                        requiredCols.splice(index, 1);
                    }

                    if(item == 'PO Number') {
                        orderNumIndex = i;
                    }
                    else if(item == 'Contact Person') {
                        contactPersonIndex = i;
                    }
                    else if(item == 'Email') {
                        emailIndex = i;
                    }
                    else if(item == 'Submitter') {
                        submitterNotClIndex = i;
                    }
                    else if(item == 'Submitter Email') {
                        submitterEmailIndex = i;
                    }
                    else if(item == 'Sample Number') {
                        sampleNameIndex = i;
                    }
                    else if(item == 'Lot') {
                        batchNumIndex = i;
                    }
                    else if(item == 'Production Date') {
                        productionDateIndex = i;
                    }
                    else if(item == 'Expiration Date') {
                        expirationDateIndex = i;
                    }
                    else if(item == 'Manufacturer') {
                        manufacturerIndex = i;
                    }
                    else if(item == 'Origin') {
                        pooIndex = i;
                    }
                    else if(item == 'Sample Form') {
                        sampleFormIndex = i;
                    }
                    else if(item == 'Mode of Packing') {
                        ModesOfPackingIndex = i;
                    }
                    else if(item == 'Storage Requirement') {
                        storageRequirementIndex = i;
                    }
                    else if(item == 'Product Category') {
                        productCategoryIndex = i;
                    }
                    else if(item == 'Result Name') {
                        resultNameColIndex = i;
                    }
                    
                });

                if(requiredCols.length > 0) {
                    setResponseMsg('*Column(s) ' + requiredCols.join() + ' are missing');
                    setResponseStatus(false);
                    return;
                }

                if(!csvContentArray[1] || csvContentArray[1].length === 0 || !csvContentArray[1][0]) {
                    setResponseMsg('*Row 2 is missing.');
                    setResponseStatus(false);
                    return;
                }

                //set first data row fixed content
                // Client Details
                setCompanyName('Synlait Milk Limited');
                setClientReference('');
                setAddress('1028 Heslerton Road - RD13 - Rakaia');
                setPhone('');
                setQuoteNo('');
                setOrderNo(csvContentArray[1][orderNumIndex] ? csvContentArray[1][orderNumIndex] : '');
                setContactPerson(csvContentArray[1][contactPersonIndex] ? csvContentArray[1][contactPersonIndex] : '');
                setEmail(csvContentArray[1][emailIndex] ? csvContentArray[1][emailIndex] : '');
                setSubmitter(csvContentArray[1][submitterNotClIndex] ? csvContentArray[1][submitterNotClIndex] : '');
                let submitterEmailArray = [];
                submitterEmailArray.push(csvContentArray[1][submitterEmailIndex] ? csvContentArray[1][submitterEmailIndex] : '');
                setSubmitterEmail(submitterEmailArray);
                setInvoiceTo('');
                setAdditionalComments('');

                // Sample Information
                setBatchNo(csvContentArray[1][batchNumIndex] ? csvContentArray[1][batchNumIndex] : '');
                setProductionDate(csvContentArray[1][productionDateIndex] ? csvContentArray[1][productionDateIndex] : '');
                setExpirationDate(csvContentArray[1][expirationDateIndex] ? csvContentArray[1][expirationDateIndex] : '');
                setManufacturer(csvContentArray[1][manufacturerIndex] ? csvContentArray[1][manufacturerIndex] : '');
                setApprovalNo('');
                setOriginPlace(csvContentArray[1][pooIndex] ? csvContentArray[1][pooIndex] : '');
                setImporter('');
                setQuantity('');
                setWeightUnit('');
                setSampleColor('');
                setUnitId('');
                setInitialImport('No');
                setSampleForm(csvContentArray[1][sampleFormIndex] ? csvContentArray[1][sampleFormIndex] : '');
                if((csvContentArray[1][ModesOfPackingIndex] && csvContentArray[1][ModesOfPackingIndex] === 'Sealed') || 
                (csvContentArray[1][ModesOfPackingIndex] && csvContentArray[1][ModesOfPackingIndex] === 'Unsealed')) {
                    setPackingMode('Pre-Packed');
                    setPrePackagedType(csvContentArray[1][ModesOfPackingIndex]);
                }
                else {
                    setPackingMode('Bulk');
                    setPrePackagedType('');
                }

                if(csvContentArray[1][storageRequirementIndex] && csvContentArray[1][storageRequirementIndex].includes('Room Temperature')) {
                    setStorageRequirement('Room Temperature');
                    setOtherStorageRequirement('');
                }
                else if(csvContentArray[1][storageRequirementIndex] && csvContentArray[1][storageRequirementIndex].includes('Cold Storage')) {
                    setStorageRequirement('Cold Storage');
                    setOtherStorageRequirement('');
                }
                else if(csvContentArray[1][storageRequirementIndex] && csvContentArray[1][storageRequirementIndex].includes('Freeze')) {
                    setStorageRequirement('Freeze');
                    setOtherStorageRequirement('');
                }
                else {
                    setStorageRequirement('Other');
                    setOtherStorageRequirement(csvContentArray[1][storageRequirementIndex] ? csvContentArray[1][storageRequirementIndex] : '');
                }

                let prodCate = '0'
                if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Infant Formula Stage 1')) {
                    prodCate ='1';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Infant Formula Stage 2')) {
                    prodCate = '2';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Infant Formula Stage 3')) {
                    prodCate = '3';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Infant Formula Child')) {
                    prodCate = '4';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Milk Power')) {
                    prodCate = '5';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Liquid Milk')) {
                    prodCate = '6';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Whey Powder')) {
                    prodCate = '7';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Cheese')) {
                    prodCate = '8';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Honey')) {
                    prodCate = '9';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Yoghurt')) {
                    prodCate = '10';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Cream')) {
                    prodCate = '11';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Base Powder')) {
                    prodCate ='12';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('Pet Food')) {
                    prodCate = '13';
                }
                else if(csvContentArray[1][productCategoryIndex] && csvContentArray[1][productCategoryIndex].includes('UHT Milk')) {
                    prodCate = '14';
                }
                else {
                    prodCate = '0'; //other
                }
                setProductCategory(prodCate);

                //read unfixed content
                let sampleNumberArray = [];
                let testInfoArray = [];
                let testInfoErrMsgArray = [];
                let tempPromptMsg = '';

                for(let i = 1; i < csvContentArray.length; i++) {
                    if(!csvContentArray[i][resultNameColIndex]) { 
                        if(i != csvContentArray.length - 1) {
                            tempPromptMsg = tempPromptMsg + ' *Row ' + (i+1) + ' empty Result Name column';
                        }
                        continue; 
                    }
                    
                    // Sample Information
                    //setSampleName();
                    if(!sampleNumberArray.includes(csvContentArray[i][sampleNameIndex])) {
                        sampleNumberArray.push(csvContentArray[i][sampleNameIndex]);
                    }

                    let matchedTref = matchingObj[csvContentArray[i][resultNameColIndex]]; //array or str
                    
                    if(matchedTref == undefined) { //if no matching
                        productCategoryTests.find((t, j) => {
                            if(t.TestDescrip.toLowerCase().includes(csvContentArray[i][resultNameColIndex].toLowerCase()) || 
                                csvContentArray[i][resultNameColIndex].toLowerCase().includes(t.TestDescrip.toLowerCase())) {
                                matchedTref = t.TestRef;
                            }
                        });
                        if(!matchedTref) { //still no matching
                            tempPromptMsg = tempPromptMsg + ' *No matching for Row ' + (i+1) + ' Result Name ' + csvContentArray[i][resultNameColIndex];
                            continue;
                        }
                    }

                    if(Array.isArray(matchedTref)) { //check if IF tests
                        if(csvContentArray[i][resultNameColIndex] == 'Appearance' || csvContentArray[i][resultNameColIndex] == 'Color' ||
                            csvContentArray[i][resultNameColIndex] == 'Colour' || csvContentArray[i][resultNameColIndex] == 'Odor and Taste' ||
                            csvContentArray[i][resultNameColIndex] == 'Carbohydrate' || csvContentArray[i][resultNameColIndex] == 'Carbohydrate (available)' ||
                            csvContentArray[i][resultNameColIndex] == 'Carbohydrate kJ' || csvContentArray[i][resultNameColIndex] == 'Total Carbohydrate' ||
                            csvContentArray[i][resultNameColIndex] == 'Energy' || csvContentArray[i][resultNameColIndex] == 'Energy kJ' ||
                            csvContentArray[i][resultNameColIndex] == 'Energy kJ/100g' || csvContentArray[i][resultNameColIndex] == 'Lactose/ Total Carbohydrates' ||
                            csvContentArray[i][resultNameColIndex] == 'Whey Protein/ Protein' || csvContentArray[i][resultNameColIndex] == 'Ca/P'
                        ) {
                            matchedTref = parseInt(prodCate) <= 4 ? matchedTref[parseInt(prodCate)-1] : matchedTref[3];
                        }
                    }

                    if(matchedTref){
                        //Check duplicate
                        let firstMatchIndex = null;
                        if (Array.isArray(matchedTref)) {
                            let tempIndex = [];
                            //check each tref in array
                            for(let i = 0; i < matchedTref.length; i++) {
                                testInfoArray.find((e, index) => {
                                    if(e.testRef === matchedTref[i]) {
                                        tempIndex.push(index);
                                        firstMatchIndex = index;
                                    }
                                });
                                
                                if(!tempIndex[i]) { 
                                    //overwrite matchedTref to str
                                    firstMatchIndex = null;
                                    matchedTref = matchedTref[i];
                                    break; 
                                }
                            }
                        }
                        else {
                            testInfoArray.find((e, index) => {
                                if(e.testRef === matchedTref) {
                                    firstMatchIndex = index;
                                }
                            });
                        }

                        if(firstMatchIndex != null) {
                            //update Test Note if needed
                            if(!testInfoArray[firstMatchIndex].note.includes(csvContentArray[i][sampleNameIndex])) {
                                testInfoArray[firstMatchIndex].note = testInfoArray[firstMatchIndex].note + ',' + csvContentArray[i][sampleNameIndex];
                            }
                        }
                        else {
                            // add none existing test
                            //find test item index
                            let testItemIndex = null;
                            productCategoryTests.find((t, i) => {
                                if(t.TestRef === matchedTref) {
                                    testItemIndex = i;
                                }
                            });
                            if(testItemIndex == null) { 
                                tempPromptMsg = tempPromptMsg + ' *No test matching for ' + csvContentArray[i][resultNameColIndex];
                                testInfoArray.push({procedRef : '', testRef : '',  methodRef : '', note : '', unit : ''});
                                testInfoErrMsgArray.push(csvContentArray[i][resultNameColIndex]);
                                continue;
                            }
                            else {
                                testInfoArray.push({procedRef : productCategoryTests[testItemIndex].ProcedRef, testRef : productCategoryTests[testItemIndex].TestRef,  methodRef : productCategoryTests[testItemIndex].MethodRef, note : csvContentArray[i][sampleNameIndex], unit : productCategoryTests[testItemIndex].Units});
                                testInfoErrMsgArray.push('');
                            }
                        }
                    }
                };
                sampleNumberArray.sort((a, b) => a - b); //ASC
                let sampleNumberStr = sampleNumberArray.join(",");
                setSampleName(sampleNumberStr);
                setTestInfo(testInfoArray);
                setTestInfoErrMsg(testInfoErrMsgArray);
                setResponseMsg(tempPromptMsg ? tempPromptMsg : 'File read completed');
                setResponseStatus(tempPromptMsg ? false : true);
                break;

            default:
                return null;
        }
    }
}


export default readClientCsvFileContent;