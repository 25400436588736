import CnasReportTemplate from './CnasReportTemplate';
import CnasChnReportTemplate from './CnasChnReportTemplate';
import IanzReportTemplate from './IanzReportTemplate';
import React, { useState, useEffect } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import api from '../../config/Api';
//import { PDFViewer } from "@react-pdf/renderer";


const DownloadReportCatalog = ({selectedRow, registrationList, setSelectedRow, setDownloadReportCatalog}) => {

    {/* Used for testing purposes */}
    // const viewerStyle = {
    //     display: "block",
    //     margin: "0 auto",
    //     width: "40vw",
    //     height: "85vh"
    // };

    const [accessUser, setAccessUser] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [currentReg, setCurrentReg] = useState(selectedRow ? selectedRow.URegRef : null); // current selected reg number
    const [currentSampleNumber, setCurrentSampleNumber] = useState(null); // current selected sample number
    const [currentTestLabelStr, setCurrentTestLabelStr] = useState(""); // current selected test label list as string (CSS)
    const [sampleNumberArray, setSampleNumberArray] = useState(null); //List of sample numbers in report data

    const [isSelectedAllSamples, setIsSelectedAllSamples] = useState(false);
    const [isSelectedAllTestOfCurrentSample, setIsSelectedAllTestOfCurrentSample] = useState(false);
    const [selectedSampleTests, setSelectedSampleTests] = useState([]);  // List of selected sample test for report
    const [filteredReportData, setFilteredReportData] = useState(null);  // user filtered report data
    const [showDownloadLink, setShowDownloadLink] = useState(false);
    const [loading, setLoading] = useState(false);
      
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const filterDistinctSampleNumbers = (array) => {
        if(!(array && array.reportData)) { 
            setSampleNumberArray([]);
            return; 
        }
        const sampleNums = array.reportData.map(item => item.SampNo);
        setSampleNumberArray(sampleNums.filter((num, index) => sampleNums.indexOf(num) === index));
    }

    const selectTestToArray = (test) => {
        let tempArray = selectedSampleTests;
        let sampleTest = { sampleNo: currentSampleNumber, testLabel: test };
        let index = -1;
        let tempCurrentTestLabelStr = "";

        for(let i = 0; i < selectedSampleTests.length; i++) {
            if(selectedSampleTests[i].sampleNo === sampleTest.sampleNo && selectedSampleTests[i].testLabel === sampleTest.testLabel) {
                index = i;
            }
            if(selectedSampleTests[i].sampleNo === currentSampleNumber) {
                tempCurrentTestLabelStr += selectedSampleTests[i].testLabel + " ";
            }
        }

        if(index >= 0) { 
            tempArray.splice(index, 1); 
            setSelectedSampleTests(tempArray);
            setCurrentTestLabelStr(tempCurrentTestLabelStr.replace(test, ""));
            if(tempArray.filter(o => o.sampleNo === currentSampleNumber).length === 0) {
                setIsSelectedAllTestOfCurrentSample(false);
            }
            if(reportData.reportData.length > tempArray.length) {
                setIsSelectedAllSamples(false);
            }
            return;
        }
        tempArray.push(sampleTest);
        setSelectedSampleTests(tempArray);
        setCurrentTestLabelStr(tempCurrentTestLabelStr + test + " ");
        if(tempArray.filter(o => o.sampleNo === currentSampleNumber).length === reportData.reportData.filter(o => o.SampNo === currentSampleNumber).length) {
            setIsSelectedAllTestOfCurrentSample(true);
        }
        if(reportData.reportData.length === tempArray.length) {
            setIsSelectedAllSamples(true);
        }
    }

    const filterReportData = () => {
        if(reportData && reportData.reportData) {
            let tempData = reportData.reportData.filter((data, index) => selectedSampleTests.some(st => (st.sampleNo === data.SampNo && st.testLabel ===data.RptLabel )));
            setFilteredReportData({reportData: tempData, reportInfo: reportData.reportInfo});
            setShowDownloadLink(true);
            return;
        }
        setShowDownloadLink(false);
    }

    const clearCurrentData = () => {
        setReportData(null);
        setCurrentReg(null);
        setCurrentSampleNumber(null);
        setCurrentTestLabelStr("");
        setSampleNumberArray([]);
        setSelectedSampleTests([]);
        setFilteredReportData(null);
        setShowDownloadLink(false);
        setIsSelectedAllSamples(false);
        setIsSelectedAllTestOfCurrentSample(false);
    }

    const selectRemoveAllSampleTests = () => {
        if(!isSelectedAllSamples) { // Select all
            let tempTestLabelStr = "";
            let tempSampleTests = [];

            for(let i=0; i<reportData.reportData.length; i++) {
                if(currentSampleNumber !== null) { 
                    if(reportData.reportData[i].SampNo === currentSampleNumber) {
                        tempTestLabelStr += reportData.reportData[i].RptLabel + " ";
                    }
                }
                tempSampleTests.push({ sampleNo: reportData.reportData[i].SampNo, testLabel: reportData.reportData[i].RptLabel })
            }

            setCurrentTestLabelStr(tempTestLabelStr);
            setSelectedSampleTests(tempSampleTests);
            setIsSelectedAllTestOfCurrentSample(true);
            return;
        }
        setCurrentTestLabelStr("");
        setSelectedSampleTests([]);
        setIsSelectedAllTestOfCurrentSample(false);
        setShowDownloadLink(false);
    }

    const selectRemoveAllTestOfCurrentSample = () =>{
        if(!isSelectedAllTestOfCurrentSample) { // Select all
            let tempTestLabelStr = "";
            let tempSampleTests = selectedSampleTests;

            for(let i=0; i<reportData.reportData.length; i++) {
                if(currentSampleNumber !== null) { 
                    if(reportData.reportData[i].SampNo === currentSampleNumber) {
                        if(!selectedSampleTests.some(o => o.sampleNo === reportData.reportData[i].SampNo && o.testLabel === reportData.reportData[i].RptLabel)) {
                            tempTestLabelStr += reportData.reportData[i].RptLabel + " ";
                            tempSampleTests.push({ sampleNo: reportData.reportData[i].SampNo, testLabel: reportData.reportData[i].RptLabel })
                        }
                    }
                }
            }

            setCurrentTestLabelStr(tempTestLabelStr);
            setSelectedSampleTests(tempSampleTests);
            if(reportData.reportData.length === tempSampleTests.length) {
                setIsSelectedAllSamples(true);
            }
            return;
        }
        
        let tempSampleTests = selectedSampleTests;
        if(currentSampleNumber !== null) { 
            tempSampleTests = tempSampleTests.filter(o => o.sampleNo !== currentSampleNumber);
        }
        setSelectedSampleTests(tempSampleTests);
        setCurrentTestLabelStr("");
        if(reportData.reportData.length > tempSampleTests.length) {
            setIsSelectedAllSamples(false);
        }
    }

    const DownloadReportLink = ({setShowDownloadLink}) => {
        const [initialize, setInitialize] = useState(true);

        useEffect(() => {
            const timeout = setTimeout(() => {
                setInitialize(false);
            }, 3000);
        },[]);

        return (
            <>
                {filteredReportData.reportData[0].PrtFile.includes('IANZ') ? 
                    <div className="px-6 py-3">
                        <button 
                            type="button" 
                            className="px-3 py-2 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            onClick={() => {setShowDownloadLink(false)}}
                            disabled={initialize}
                        >
                            <PDFDownloadLink document={<IanzReportTemplate data={filteredReportData} />} fileName={currentReg}>
                            {({ blob, url, loading, error }) =>
                                initialize ? 'Initializing...' : loading ? 'Loading...' : error ? 'Error' : 'IANZ'
                            }
                            </PDFDownloadLink>
                        </button>    
                    </div>
                
                : 
                <>
                    <div className="px-6 py-3">
                        <button 
                            type="button" 
                            className="px-3 py-2 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            onClick={() => {setShowDownloadLink(false)}}
                            disabled={initialize}
                        >

                            <PDFDownloadLink document={<CnasReportTemplate data={filteredReportData} />} fileName={currentReg}>
                            {({ blob, url, loading, error }) =>
                                initialize ? 'Initializing...' : loading ? 'Loading...' : error ? 'Error' : 'CNAS'
                            }
                            </PDFDownloadLink>
                        </button>    
                    </div>
                    <div className="px-6 py-3">
                        <button 
                            type="button" 
                            className="px-3 py-2 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
                            onClick={() => {setShowDownloadLink(false)}}
                            disabled={initialize}
                        >
                            <PDFDownloadLink document={<CnasChnReportTemplate data={filteredReportData} />} fileName={currentReg}>
                            {({ blob, url, loading, error }) =>
                                initialize ? 'Initializing...' : loading ? 'Loading...' : error ? 'Error' : 'CNAS-CHN'
                            }
                            </PDFDownloadLink>
                        </button>    
                    </div>
                </>
                }
            </>
        );
    }

    const getRegistrationReportData = async (regTID) => {
        setLoading(true);
        await api.get('/access/registration-report-data', { params: { regTID: regTID } })
                .then(res => {
                    if(res.data.success) { 
                        setReportData(res.data.data);
                        filterDistinctSampleNumbers(res.data.data);
                    }else {
                        setReportData(null);
                    };
                })
                .catch(err => {
                    console.log(err.response.data.message)
                });
        setLoading(false);
    }

    const selectButtonGroupOption = async (option) => {
        const a = document.createElement('a');
        const fileName = (selectedRow && selectedRow.URegRef) ? selectedRow.URegRef : (currentReg ? currentReg : 'Unknow');
        a.download = fileName + '.' + option.toLowerCase();
        a.href = '';
        if(option === 'PDF') {
            //a.href = attachmentArry[0];
        }
        else {
            if(reportData && reportData.csvRpt) {
                a.href = reportData.csvRpt;
            }
        }
        a.click();
        a.remove();
        //setShowButtonGroupOptions(false);
    }

    useEffect(() => {
        if(selectedRow) { getRegistrationReportData(selectedRow.RegTID); }
        if(localStorage.getItem("access-user")) {
            setAccessUser(JSON.parse(localStorage.getItem("access-user")));
        }
    }, []);

    return (
        <div className="w-screen h-screen fixed left-0 top-0 bg-gray-800 bg-opacity-80 z-20 flex items-center">
            <div className="w-1/2 mx-auto">
                <div className="flex rounded-t-lg shadow bg-white">
                    <div className="w-auto">
                        <div className="py-4 px-16 text-gray-900 text-center bg-gray-100 rounded-tl-lg">
                            Registration Numbers
                        </div>
                        <div className="my-4 max-h-540 h-540 overflow-y-auto">
                            { !selectedRow && registrationList.map((reg, index) => (
                                reg.StatCd !== "No Entry" &&
                                <div
                                    onClick={() => {clearCurrentData(); setCurrentReg(reg.URegRef); getRegistrationReportData(reg.RegTID); }}
                                    key={index}
                                    className={classNames(
                                    selectedSampleTests && selectedSampleTests.length > 0 && currentReg === reg.URegRef ? 'bg-green-100 text-green-800 hover:bg-green-200' : currentReg === reg.URegRef ? 'bg-gray-200 text-gray-900' : 'text-gray-900 hover:bg-gray-100',
                                    'w-full cursor-pointer pl-14 py-2 text-sm font-medium rounded-md'
                                )}>
                                   {reg.URegRef}
                                </div>
                            ))}

                            { selectedRow &&
                                <div
                                    className={classNames(
                                    currentReg === selectedRow.URegRef ? 'bg-green-100 text-green-800' : 'text-gray-900 hover:bg-gray-100',
                                    'w-full cursor-pointer pl-14 py-2 text-sm font-medium rounded-md'
                                )}>
                                    {selectedRow.URegRef}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="border-l border-r w-auto">
                        <div className="py-4 px-10 text-gray-900 text-center bg-gray-100">
                            Sample Numbers
                        </div>
                        <div className="my-4 max-h-540 h-540 overflow-y-auto">
                            { (reportData && reportData.reportData) && sampleNumberArray !== null && sampleNumberArray.length !== 0 &&
                                <div
                                    onClick={() => { setIsSelectedAllSamples(!isSelectedAllSamples); selectRemoveAllSampleTests();} }
                                    className={classNames(
                                    'text-blue-700 hover:bg-gray-100',
                                    'w-full cursor-pointer text-center py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                   {isSelectedAllSamples ? 'Remove All' : 'Select All'}
                                </div>
                            }
                            { (reportData && reportData.reportData) && sampleNumberArray !== null && sampleNumberArray.length > 0 && sampleNumberArray.map((num, index) => (
                                <div
                                    onClick={() => { setShowDownloadLink(false); setCurrentTestLabelStr(""); setCurrentSampleNumber(num); setIsSelectedAllTestOfCurrentSample(reportData.reportData.filter(o => o.SampNo === num).length > selectedSampleTests.filter(o => o.sampleNo === num).length ? false : true); } }
                                    key={index}
                                    className={classNames(
                                    selectedSampleTests && selectedSampleTests.length>0 && selectedSampleTests.some(o => o.sampleNo === num) ? 'bg-green-100 text-green-800 hover:bg-green-200' : currentSampleNumber  === num ? 'bg-gray-200 text-gray-900' : 'text-gray-900 hover:bg-gray-100',
                                    'w-full cursor-pointer text-center py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                   {num}
                                </div>
                            ))}
                            {sampleNumberArray !== null && sampleNumberArray.length === 0 &&
                                <div
                                    className={classNames(
                                    loading ? 'bg-blue-100 text-blue-800' : 'bg-red-100 text-red-800',
                                    'w-full text-center py-2 text-sm font-medium rounded-md'
                                )}
                                >
                                    {loading ? 'Loading ...' : 'No Data'}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex-grow">
                        <div className="py-4 text-gray-900 text-center bg-gray-100 rounded-tr-lg">
                            Tests
                        </div>
                        <div className="my-4 max-h-540 h-540 overflow-y-auto">
                            { (reportData && reportData.reportData) && currentSampleNumber && reportData.reportData.some(o => o.SampNo === currentSampleNumber) && 
                                <div
                                    onClick={() => { setIsSelectedAllTestOfCurrentSample(!isSelectedAllTestOfCurrentSample); selectRemoveAllTestOfCurrentSample(); } }
                                    className={classNames(
                                    'text-blue-700 hover:bg-gray-100',
                                    'w-full cursor-pointer text-center py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                   {isSelectedAllTestOfCurrentSample ? 'Remove All' : 'Select All'}
                                </div>
                            }
                            { (reportData && reportData.reportData) && reportData.reportData.map((data, index) => (
                                currentSampleNumber === data.SampNo &&
                                <div
                                    onClick={() => {selectTestToArray(data.RptLabel); setShowDownloadLink(false); }}
                                    key={index}
                                    className={classNames(
                                    selectedSampleTests && selectedSampleTests.length>0 && selectedSampleTests.some(o => o.sampleNo === data.SampNo && o.testLabel === data.RptLabel) ? 'bg-green-100 text-green-800 hover:bg-green-200' : currentTestLabelStr.includes(data.RptLabel) ? 'bg-green-100 text-green-800 hover:bg-green-200' : 'text-gray-900 hover:bg-gray-100',
                                    'w-full cursor-pointer pl-12 py-2 text-sm font-medium rounded-md')}
                                >
                                    {data.RptLabel}
                                </div>
                            ))}
                            {(reportData && reportData.reportData) && currentSampleNumber && !reportData.reportData.some(o => o.SampNo === currentSampleNumber) && 
                                <div
                                    className={classNames(
                                        'bg-red-100 text-red-800',
                                        'w-full text-center py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                    No Data
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex rounded-b-lg shadow bg-gray-100 justify-between">
                    <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <button onClick={()=>{ clearCurrentData(); setDownloadReportCatalog(false); setSelectedRow(null); }} type="button" className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Close
                        </button>      
                    </div>
                    <div className="flex justify-between">
                        {accessUser && accessUser.company && accessUser.company === 'SYNLAIT' && ((selectedRow && selectedRow.URegRef) || currentReg) &&
                        <div className="px-6 py-3 text-xs font-medium text-gray-500">
                            <button 
                                className="bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 px-3 py-2 shadow-sm text-sm leading-4 font-medium rounded-md text-white"
                                onClick={ () =>{ selectButtonGroupOption('csv'); }}
                            >
                                CSV
                            </button>
                        </div>}

                        { showDownloadLink && selectedSampleTests && selectedSampleTests.length > 0 && <DownloadReportLink setShowDownloadLink={setShowDownloadLink}/> }

                        <div className="px-6 py-3 text-xs font-medium text-gray-500">
                            <button 
                                onClick={()=>{ filterReportData(); }} 
                                type="button" 
                                className={`${!(selectedSampleTests && selectedSampleTests.length) > 0 ? 'bg-gray-600 hover:bg-gray-700' : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'} px-3 py-2 shadow-sm text-sm leading-4 font-medium rounded-md text-white`}
                                disabled={!(selectedSampleTests && selectedSampleTests.length > 0)}
                            >
                                Generate Link
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Used for testing purposes */}
            {/* <PDFViewer style={viewerStyle}>
                {filteredReportData &&<CnasReportTemplate data={filteredReportData}/>}
            </PDFViewer> */}
        </div>
    );
}

export default DownloadReportCatalog;